export default class Titulo {
    constructor() {
        this.apontamento_cd_municipio = null;
        this.apontamento_dt_protocolo = null;
        this.apontamento_nu_cartorio = null;
        this.apontamento_nu_protocolo = null;
        this.cd_apresentante = null;
        this.cd_cep_devedor = null;
        this.cd_cep_sacador = null;
        this.cd_especie_titulo = null;
        this.cd_ibge_envio_comarca = null;
        this.cd_ibge_envio_municipio = null;
        this.cd_irregularidade = null;
        this.cd_status = null;
        this.cd_uf_devedor = null;
        this.cd_uf_sacador = null;
        this.cnpj_sacador = null;
        this.ds_bairro_devedor = null;
        this.ds_email_devedor = null;
        this.ds_endereco_devedor = null;
        this.ds_endereco_devedor_cra = null;
        this.ds_endereco_sacador = null;
        this.ds_nosso_numero = null;
        this.ds_nu_cadastro_devedor = null;
        this.ds_numero_titulo = null;
        this.ds_revisao = null;
        this.irregulares = null;
        this.dt_atualizacao = null;
        this.dt_cadastro = null;
        this.dt_emissao = null;
        this.dt_vencimento = null;
        this.fl_falimentar = null;
        this.id_arquivo = null;
        this.id_titulo = null;
        this.nm_cedente_favorecido = null;
        this.nm_cidade_devedor = null;
        this.nm_cidade_sacador = null;
        this.nm_devedor = null;
        this.nm_devedor_cra = null;
        this.nm_sacador = null;
        this.nu_controle_devedores = null;
        this.nu_identificacao_devedor = null;
        this.nu_linha = null;
        this.nu_parcela = null;
        this.nu_telefone_devedor = null;
        this.st_envio = null;
        this.tp_aceite = null;
        this.tp_declaracao = null;
        this.tp_endosso = null;
        this.tp_identificacao_devedor = null;
        this.tp_origem = null;
        this.vl_custas = null;
        this.vl_saldo_protesto = null;
        this.vl_titulo = null;
        this.oferta = null;
    }
}
